<template>
  <div class="page">
    <div v-if="is_weixin" class="top_right">请在浏览器中打开</div>
    <div class="app_div">
      <img :src="'https://www.fine.today/static/img/logo/' + apple_info.tag + '.png'">
      <div class="title">{{apple_info.name}}</div>
      <ul>
        <li class="desc"><div class="dian">·</div><div class="word">{{apple_info.desc}}</div></li>
        <li class="desc"><div class="dian">·</div><div class="word">{{apple_info.desc_long}}</div></li>
        <li class="desc"><div class="dian">·</div><div class="word">如果司马迁在世，他也大可能会喜欢</div></li>
      </ul>
    </div>
    <div v-if="!is_ios" class="button_load">请在手机应用商店搜索下载</div>
  </div>
</template>

<script>
import axios from 'axios'
import Utils from '../../utils/utils'
import { appInfo } from '@/components/config.js'

export default {
  name: 'Downloads',
  data () {
    return {
      is_weixin: Utils.is_weixin(),
      is_mobile: Utils.is_mobile(),
      is_ios: Utils.is_ios(),
      apple_info: appInfo
    }
  },
  mounted () {
    // if (this.is_mobile && !this.is_weixin) {
    if (this.is_mobile) {
      if (this.is_ios) {
        location.href = 'https://apps.apple.com/cn/app/id' + this.apple_info.appleId
      } else {
        // location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=' + this.android_id
      }
    }
    // this.get_index()
  },
  methods: {
    get_index () {
      axios.post('/log_sth_unlogin/', { tag: 'downloads_app', qid: this.$route.query.qid })
        .then(res => {
          // console.log(res)
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            // return
          }
        })
    }
  }
}
</script>

<style scoped>
/* html,
body,
#app,.page{
  width: 100%;
  height: 100%;
} */
.page{
  background: url('https://www.wordvector.com/static/img/app_share_background.png') center no-repeat;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
}
.top_right{
    width: 184px;
    height: 67px;
    background: url('https://www.wordvector.com/static/img/app_share_topright.png') center no-repeat;
    background-size: 184px auto;
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
    line-height: 83px;
    position: absolute;
    right: 25px;
    top: 0;
}
.app_div{
  text-align: center;
  position: absolute;
  top: 40%;
  width: 100%;
  transform: translateY(-50%);
}
.app_div img{
  border-radius: 36px;
  width: 160px;
  height: 160px;
}
.app_div img{
  display: block;
  margin: 10px auto;
}
.app_div .title{
  font-size: 32px;
  font-weight: 800;
  color: #50b7c1;
  margin-bottom: 20px;
}
.app_div .desc{
  font-size: 16px;
  font-weight: 400;
  color: #000;
  text-align: start;
  margin: 6px 20px 6px 20px;
}
.app_div .desc div{
  display: inline-block;
  vertical-align: top;
}
.app_div .desc .dian{
  font-size: 40px;
  line-height: 1px;
  padding-top: 10px;
  /* color: #50b7c1; */
}
.app_div .desc .word{
  margin-left: 10px;
  width: calc(100% - 40px);
}
.app_div .desc_long{
  font-size: 16px;
  font-weight: 400;
  margin: 0px 30px;
  color: #50b7c1;
  /* color: #aaa; */
}

.button_load{
  position: absolute;
  bottom: 100px;
  /* width: 450px; */
  margin: auto 60px;
  padding: 6px 16px;
  border: 1px solid #50b7c1;
  border-radius: 40px;
  font-size: 16px;
  color: #50b7c1;
  left: 0;
  right: 0;
}
</style>
